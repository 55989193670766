const api = {
	// LOGIN
	async login(details) {
		const resultado = await fetch(
			'https://security-api.sustainaballs.com/auth/login',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(details),
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	// LOGIN
	async register(details) {
		const resultado = await fetch(
			'https://eichzwrco6.execute-api.eu-central-1.amazonaws.com/dev/user/register',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'sustainaballsApiKey!!',
				},
				body: JSON.stringify(details),
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async setTicket(details, user) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch(
			'https://control-api.sustainaballs.com/sales',
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
				body: JSON.stringify(details),
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async getNumeroFactura(user) {
		const auto = 'Bearer ' + user.token;
		// https://mgufl8sid1.execute-api.eu-central-1.amazonaws.com/dev/
		// https://wxbbuqhej2.execute-api.eu-central-1.amazonaws.com/dev
		const resultado = await fetch(
			'https://control-api.sustainaballs.com/sales/number',
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.text())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async getTickets(user, desde, hasta) {
		const auto = 'Bearer ' + user.token;
		// https://6dffzfmwcj.execute-api.eu-central-1.amazonaws.com/dev
		// https://mgufl8sid1.execute-api.eu-central-1.amazonaws.com/dev/
		const date = new Date();
		let fecha;
		if (desde === undefined) {
			const mes =
				date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
			const dia = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate();
			fecha = date.getFullYear() + '-' + mes + '-' + dia;
		}
		const resultado = await fetch(
			desde !== undefined
				? 'https://control-api.sustainaballs.com/sales?startDate=' +
						desde +
						'&endDate=' +
						hasta
				: 'https://control-api.sustainaballs.com/sales?startDate=' +
						fecha,
			{
				// const resultado = await fetch('https://dev.sales.dotiplatform.com/sales?startDate=2022-05-19', {
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
				// body: JSON.stringify(details),
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	// GASTOS
	async getExpenses(user, desde, hasta) {
		const auto = 'Bearer ' + user.token;
		// https://avy6dqn143.execute-api.eu-central-1.amazonaws.com/dev
		// https://pvkpfrclik.execute-api.eu-central-1.amazonaws.com/dev
		const date = new Date();
		let fecha;
		if (desde === undefined) {
			const mes =
				date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
			const dia = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate();
			fecha = date.getFullYear() + '-' + mes + '-' + dia;
		}
		const resultado = await fetch(
			desde === undefined
				? 'https://control-api.sustainaballs.com/expense?startDate=' +
						fecha
				: 'https://control-api.sustainaballs.com/expense?startDate=' +
						desde +
						'&endDate=' +
						hasta,
			{
				// const resultado = await fetch('https://dev.expense.dotiplatform.com/expense?startDate=2022-05-19', {
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async downloadFactura(user, id) {
		const auto = 'Bearer ' + user.token;
		// https://mgufl8sid1.execute-api.eu-central-1.amazonaws.com/dev/
		// https://wxbbuqhej2.execute-api.eu-central-1.amazonaws.com/dev
		const resultado = await fetch(
			'https://control-api.sustainaballs.com/expense/' + id,
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.text())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async deleteFactura(user, id) {
		const auto = 'Bearer ' + user.token;
		// https://mgufl8sid1.execute-api.eu-central-1.amazonaws.com/dev/
		// https://wxbbuqhej2.execute-api.eu-central-1.amazonaws.com/dev
		const resultado = await fetch(
			'https://control-api.sustainaballs.com/expense/' + id,
			{
				method: 'DELETE',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.text())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async setExpenses(user, details) {
		// console.log('Llegooo?', details)
		// const auto = 'Bearer ' + user.token
		// const resultado = await fetch('https://dev.expense.dotiplatform.com/expense', {
		//     method: 'POST',
		//     headers: {  'Content-Type': 'multipart/form-data', 'Authorization': auto }
		// })
		// .then((res) => res.json())
		// .then((res) => { return res })
		// .catch((err) => { return err });
		// console.log(resultado)
		// return resultado
		// https://avy6dqn143.execute-api.eu-central-1.amazonaws.com/dev
		// https://pvkpfrclik.execute-api.eu-central-1.amazonaws.com/dev
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch(
			'https://control-api.sustainaballs.com/expense',
			{
				// const resultado = await fetch('https://avy6dqn143.execute-api.eu-central-1.amazonaws.com/dev/expense', {
				method: 'POST',
				// headers: { 'Content-Type': 'application/json', 'Authorization': 'Doti df90a1d1-8ca5-4105-ac40-773ef66ca84a' },
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
				body: JSON.stringify(details),
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	test() {
		// console.log(this.mapGetters.authUser)
	},
	//Endpoint de Marcas
	async getMarcas(user) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/tube',
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async setMarca(user, details) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/tube',
			{
				method: 'POST',
				headers: { 'Authorization': auto },
				body: details,
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async deleteMarca(user, id) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/tube/' + id,
			{
				method: 'DELETE',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	//Endpoint de Clientes
	async getClientes(user) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/customer',
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async setCliente(user, details) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/customer',
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
				body: JSON.stringify(details),
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async setClienteWithFile(user, details) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/customer/upload',
			{
				method: 'POST',
				headers: { 'Authorization': auto },
				body: details,
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async deleteCliente(user, id) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/customer/' + id,
			{
				method: 'DELETE',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	//Endpoint de Partidas
	async getPartidos(user, startDate) {
		const auto = 'Bearer ' + user.token;

		let parameters = '';
		if (startDate !== null) {
			parameters += "?startDate=" + startDate;
			parameters += "&sportCenterId=" + 1;
		}

		const resultado = await fetch('https://control-api.sustainaballs.com/match' + parameters,
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	//Endpoints Vendings
	async getVendings(user) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/vending',
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async showVending(user, id) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/vending/' + id,
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async updateVending(user, id, details) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/vending/' + id,
			{
				method: 'PUT',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
				body: JSON.stringify(details),
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},
	
	async setVendingConfig(user, details) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/vending/config',
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
				body: JSON.stringify(details),
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

    //Endpoints Sportcenter
	async getSportCenter(user) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/sportcenter',
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async showSportCenter(user, id) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/sportcenter/' + id,
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async setSportCenter(user, details) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/sportcenter',
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
				body: JSON.stringify(details),
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	 //Endpoints Estado
	 async getEstado(user) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/tpstatus',
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	 //Endpoints Estado
	 async getMarca(user) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/tube',
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	//Endpoints Mantenimiento
	async showMantenimiento(user, id, startDate, endDate) {
		const auto = 'Bearer ' + user.token;

		let parameters = id;
		if (startDate !== null) {
			parameters += "?startDate=" + startDate + "&endDate=" + endDate;
		}
		const resultado = await fetch('https://control-api.sustainaballs.com/maintenance/' + parameters,
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async getPlanes(user) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/plan',
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async setPlanes(user, details) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/plan',
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
				body: JSON.stringify(details),
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async editPlan(user, details ,id) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/' + id,
			{
				method: 'PUT',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
				body: JSON.stringify(details),
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async deletePlan(user, details) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/' + details,
			{
				method: 'DELETE',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
				// body: JSON.stringify(details),
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async getLicencias(user) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/license',
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async showLicencia(user, id) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/license/' + id,
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async getTipoCliente(user) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/tpcustomer',
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async setMaintenance(details, user) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch(
			'https://ivd9tow766.execute-api.eu-north-1.amazonaws.com/dev/maintenance',
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
				body: JSON.stringify(details),
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	async updateMaintenance(details, user) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch(
			'https://ivd9tow766.execute-api.eu-north-1.amazonaws.com/dev/maintenance',
			{
				method: 'PUT',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
				body: JSON.stringify(details),
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},

	//Endpoint de Usuarios
	async getUsuarios(user) {
		const auto = 'Bearer ' + user.token;
		const resultado = await fetch('https://control-api.sustainaballs.com/user',
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': auto },
			}
		)
			.then(res => res.json())
			.then(res => {
				return res;
			})
			.catch(err => {
				return err;
			});

		return resultado;
	},
};


export default api;
